(<template>
  <div class="relation-btn">
    <p v-if="description"
       class="relation-btn__descr"
       v-html="description"></p>
    <button :class="{'favourite-btn--block--chosen': isBlockedUser}"
            class="favourite-btn favourite-btn--block"
            @click.stop.prevent="blockHandler">
      <span class="relation-btn__btn-text">{{ blockBtnText }}</span>
    </button>
  </div>
</template>)

<script>
  export default {
    props: {
      description: {
        type: String,
        default: ''
      },
      btnText: {
        type: String,
        default: ''
      },
      person: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      blockBtnText() { return this.btnText || this.$gettext('Block'); },
      isBlockedUser() { return this.person.relation == 'blocked'; }
    },
    methods: {
      removeRelation() {
        this.$emit('startprogress');
        this.$store.dispatch('EditProfileStore/removeRelation', this.person.uid).then(() => {
          this.$skGA.emitEvent(this.$GADataConstructor.getFeedbackObjGA(false, 'block_interpreter'));
          this.$emit('stopprogress');
          this.$emit('relationchanged', true);
        });
      },
      setBlock() {
        const form = new FormData();
        form.append('relation[interpreter]', this.person.uid);
        form.append('relation[attitude_type]', 'blocked');
        this.$emit('startprogress');
        this.$store.dispatch('EditProfileStore/sendUpdatedRelation', {form, relation: 'blocked'}).then(() => {
          this.$skGA.emitEvent(this.$GADataConstructor.getFeedbackObjGA(true, 'block_interpreter'));
          this.$emit('stopprogress');
          this.$emit('relationchanged', true);
        });
      },
      blockHandler() {
        this.isBlockedUser ? this.removeRelation() : this.setBlock();
      }
    }
  };
</script>

<style scoped src="@assets/css/relation_btn.css"></style>
